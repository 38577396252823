import React from 'react';

import './style.scss';

const CardColumns = ({ cardProps, cardComponent: Card, columnCount = 3 }) => (
  <div className={`card-columns max-columns-${columnCount}`}>
    {cardProps.map((item, n) => (
      <Card key={item.key || n} {...item} />
    ))}
  </div>
);

export default CardColumns;
