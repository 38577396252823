import React from 'react';

import './style.scss';

const ItemCard = ({ title, rate, rateNotes = null, content }) => (
  <div className="card item-card">
    <div className="card-header">
      <h3 className="card-title">{title}</h3>
    </div>
    <div className="card-body">{content}</div>
    <div className="card-footer">
      <p className="rate">{rate}</p>
      {rateNotes ? <p className="rate-notes">{rateNotes}</p> : null}
      <a href="/contact" className="btn btn-primary btn-lg btn-block card-btn">
        Get in touch!
      </a>
    </div>
  </div>
);

export default ItemCard;
