import { graphql } from 'gatsby';
import get from 'lodash/get';
import Img from 'gatsby-image';
import React from 'react';
import HeaderImage from 'components/HeaderImage';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import CardColumns from 'components/CardColumns';
import ItemCard from 'components/ItemCard';

const menu = [
  {
    title: 'Mentoring and tutoring',
    rateType: 'hourly',
    rate: '$150 / hour',
    content: (
      <>
        <p>
          Whether it’s technical skills or navigating interpersonal dynamics, we
          can help you succeed.
        </p>
        <p>
          Sometimes there’s a specific problem you’re facing, and one or two
          sessions is all you need to work it out and be able to move forward.
          At other times, you may want recurring meetings for some period of
          time - during a job search, perhaps, or over a transition or product
          release. Or, perhaps you’d like that mentoring to grow with you, as
          part of an ongoing relationship to help you tackle the new challenges
          and opportunities that come along.
        </p>

        <p>
          We can work with you, however much you need. Depending on your
          situation, we may meet with you a single time, or arrange a recurring
          weekly, biweekly, or monthly meeting.
        </p>

        <p>
          We offer a free hour-long conversation to discuss your situation and
          what we can offer you.
        </p>
      </>
    ),
  },
  {
    title: 'Practice interview',
    rateType: 'fixed',
    rate: '$750 (phone interview)',
    rateNotes: 'contact us to discuss in-person interviews',
    content: (
      <>
        <p>
          Interviews are stressful. They're high stakes conversations where you
          don't even find out how well you did - just whether or not you were
          the candidate they liked best.
        </p>
        <p>
          Our experience as interviewer and hiring manager means we can tell you
          what you do well and what you can improve, so you can be more
          confident the next time you're facing someone over the phone or across
          the table.
        </p>
        <p>
          We conduct a simulated interview with you. We record the interview,
          review it and write up feedback, and provide you with the recording,
          our feedback and coaching notes, and hold a time-boxed review meeting
          to discuss it with you.
        </p>
        <p>
          We will perform phone or in-person interviews, and provide you with
          the knowledge of what you did well and what you can improve to help
          present yourself better when you interview.
        </p>
      </>
    ),
  },
  {
    title: 'Résumé review',
    rateType: 'fixed',
    rate: '$450',
    content: (
      <>
        <p>
          The first step in getting a job is to convince someone you're worth
          talking to, and most of the time, that involves a résumé. There's a
          lot of advice about what to do with your résumé - much of it
          contradictory.
        </p>
        <p>
          We can guide you with specific, actionable feedback around what
          buzzwords are important to your field, how to make your experience
          relevant, and other ways to put yourself in the best light without
          making claims you can't back up.
        </p>
        <p>
          You provide your résumé, and answer a premade questionnaire about your
          objectives. We review and mark-up your résumé with feedback, and hold
          a time-boxed review meeting to discuss it with you.
        </p>
      </>
    ),
  },
  {
    title: 'Code reviews',
    rateType: 'hourly',
    rate: '$150 / hour',
    rateNotes: 'discounts available for open source code',
    content: (
      <>
        <p>
          Some code is easy to follow and works well. Other code is a headache
          to read and has bugs that require extensive workarounds or late night
          surprises.
        </p>
        <p>
          You'd like to write the first kind, but it's not always easy. We can
          be a second pair of experienced eyes to help you find the places where
          your code could be cleaner, more efficient, and just plain better.
        </p>
        <p>
          We will read and review code you share with us, providing feedback on
          requested areas including:
        </p>
        <ul>
          <li>Clarity of structure and naming</li>
          <li>Quality and utility of comments</li>
          <li>Factoring and decoupling</li>
          <li>Reusability</li>
          <li>Maintainability</li>
          <li>Algorithmic efficiency</li>
          <li>Source control practices</li>
          <li>Code documentation (READMEs, etc.)</li>
        </ul>
        <p>
          We will provide initial cost estimates and remain within established
          time bounds when working, doing as much as is possible within your
          constraints on cost.
        </p>
        <p>
          We offer discounts when reviewing code that is part of a publicly
          available OSS project!
        </p>
      </>
    ),
  },
];

const IndividualsPage = ({ location, data }) => {
  const header = get(data, 'header.childImageSharp.fluid');
  const rainbow = get(data, 'rainbow.childImageSharp.fluid');

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="For Individuals" />
      <HeaderImage image={header}>
        <h1>For Individuals</h1>
      </HeaderImage>

      <p>
        In the software industry, company-hopping, role-shifts and occasional
        layoffs are all becoming a regular part of how things work. People come
        and go, and in the world of start-ups and bubbles, companies do the
        same. These days, the statement "you own your career" has never been
        more true.
      </p>

      <p>That doesn't mean you need to own it all by yourself.</p>

      <p>
        What is it that you need to succeed in the new and changing world of
        software? You need to be able to code, but that's only part of the job.
        You also need to be able to communicate your ideas, navigate personal
        dynamics, give and receive useful code reviews, learn new technical
        skills, maintain a work/life balance, seek new positions, and so on.
        It's a job with a lot of aspects, but all of them are things you can
        learn.
      </p>

      <p>
        So, how <em>do</em> you learn these things? Maybe from your peers, but
        they’re all busy with their own tasks and careers - and even if they
        have the time, they may not know any more than you. Maybe you can learn
        from your manager, but they may know the business instead of the
        technology - and even if they do have the skills, they’re motivated to
        do what’s best for the company, not just what’s best for you.
      </p>

      <p>
        You need to be proactive in finding the right opportunities for
        yourself, and actively look at whether you're doing work that's helping
        you and the people around you, or just treading water.
      </p>

      <p>
        That’s where we can help. Between us, we’ve spent over twenty-five years
        working in software. We know what it’s like to be both a developer and
        developer manager that spans a variety of companies, including Amazon
        and Microsoft.
      </p>

      <p>
        Not only do we have the skills - both hard and soft - we know how to
        teach them to you. From developing curriculum for Amazon’s new developer
        training, to an educational video series, to instructing at a software
        boot camp through the University of Washington, we’ve achieved success
        in helping people learn how to be better at what they do.
      </p>

      <p>
        We're here to help you. We work for <em>you</em>, so we have your
        interests in mind - not your employer's. We'll help you steer your
        career to where <em>you</em> want it - whether that's within your
        current company, or beyond it.
      </p>

      <h2>Working with us</h2>

      <p>
        To work with us, just get in touch. We'll set up an initial meeting,
        free of charge, to get to know each other and see if we can help. We’re
        available in person, over the phone or by video meeting; we know that
        finding time for things that aren’t work can be one of the hard parts of
        being in the software industry, so we’re flexible to help work with you.
        We’re not limited to “business hours”, either - we can meet with you
        during the evening, on the weekend, or even at a nearby restaurant while
        you eat lunch.
      </p>

      <h2>Our "menu"</h2>
      <p>
        For a number of common software-industry situations, we've got
        pre-existing plans to help.
      </p>

      <CardColumns cardProps={menu} cardComponent={ItemCard} columnCount={2} />

      <h2>Our commitment to diversity</h2>
      <figure className="figure float-right w-25">
        <Img fluid={rainbow} className="figure-img img-fluid rounded shadow" />
      </figure>
      <p>
        The software industry is full of people from a small set of backgrounds.
        We believe that's a failing of the industry on many levels, and we want
        to do our part to correct it.
      </p>
      <p>
        We have specific experience working with issues around gender diversity
        and LGBTQ* identities, and we're open to working with anyone.
      </p>
      <p>
        As part of this commitment, we strive to offer our services on a sliding
        scale to people who are economically disadvantaged. We're happy to
        discuss this confidentially with anyone who might benefit.
      </p>
    </Layout>
  );
};

export default IndividualsPage;

export const query = graphql`
  query IndividualsPageQuery {
    header: file(name: { eq: "nesa-by-makers-664834-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 800, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rainbow: file(name: { eq: "steve-johnson-1236783-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
